<template>
  <div class="row h-100">
    <div class="col-md-12 my-auto">
      <div class="container">
        <div class="row mx-auto">
          <div class="col-md-6 mx-auto">
            <div class="auth-wrapper">
              <div class="auth-content mb-0">
                <div class="card-body">
                  <div class="card-title text-center mb-5 font-18">
                    <p class="font-20">{{$t('first_login.subtitle')}}</p>
                    <p>{{$t('first_login.description')}}</p>
                  </div>
                  <form method="post" @submit.prevent="onSubmit('frmLogin')" data-vv-scope="frmLogin">
                    <div class="mb-4">
                      <custom-input
                        v-model="password.new"
                        name="password.new"
                        placeholder="Nova Senha"
                        type="password"
                        v-validate="{ required: true }"
                        :error="(submitted) ? errors.first('password.new') : ''">
                      </custom-input>
                    </div>
                    <div class="mb-4">
                      <custom-input
                        v-model="password.repeat"
                        name="password.repeat"
                        placeholder="Confirmar Senha"
                        type="password"
                        v-validate="{ required: true, confirmed: password.new}"
                        :error="(submitted) ? errors.first('password.repeat') : ''">
                      </custom-input>
                    </div>
                    <div class="mb-5 text-center">
                      <button class="btn btn-primary btn-padding" type="submit">{{ $t('first_login.login') }}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import router from '@/router'
import { CompanyService } from '@/services/CompanyService'
import { loginService } from '@/services/loginService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

import './login.css'
import '@/assets/css/custom.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'FirstLogin',
  metaInfo: {
    titleTemplate: '%s | Atualizar Senha'
  },
  components: { CustomInput },
  data () {
    return {
      password: {
        new: null,
        repeat: null
      },
      submitted: false,
      loading: false,
      user: null
    }
  },
  created: function () {
    let me = this
    loginService.getUserData().then(res => {
      me.user = res
    })
  },
  methods: {
    onSubmit (scopeName) {
      let me = this

      me.submitted = true
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          if (me.password.new === me.password.repeat && me.password.new !== null) {
            let data = {
              'password': me.password.new
            }

            CompanyService.setCompanyUserPassword(me.user.id, data).then(contact => {
              me.$alertSwal.toast('Senha alterada com sucesso!')
              me.$router.push({ name: 'Dashboard' })
            })
          }
        }
      })
    }
  }
}
</script>
